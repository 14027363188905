<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>{{
        currentStandard.standard_no + "《" + currentStandard.name + "》"
      }}</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="标准条目列表" icon="ios-contacts">
            <clause-list
              :columnsFormatData="columnsFormatData"
              askData="clauseListForOneStandard"
              :refreshData="update_count"
              main_width="1260px"
              :dataId="standardId"
            ></clause-list>
          </TabPane>
          <Button
            @click="openModal('addClause')"
            slot="extra"
            style="margin-right: 5px"
            >添加条目</Button
          >
        </Tabs>
      </div>
      <Modal
        v-model="modalSwicth.addClause"
        :width="800"
        :mask-closable="false"
        @on-cancel="closeModal('addClause')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>新增条目</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('addClause')"
        ></i>
        <div class="sc-content-wrapper">
          <div class="form-wrapper">
            <Form
              ref="formClause"
              :model="formItem"
              :rules="ruleValidate"
              :label-width="250"
            >
              <FormItem label="条目号：" prop="clauseNo" style="width: 438px">
                <Input v-model="formItem.clauseNo" />
              </FormItem>
              <FormItem label="条目内容：" prop="content" style="width: 650px">
                <Input v-model="formItem.content" placeholder />
              </FormItem>
              <FormItem
                label="要求文件名称："
                prop="fileName"
                style="width: 650px"
              >
                <Input v-model="formItem.fileName" placeholder />
              </FormItem>
              <FormItem label="专业类别：" prop="major" style="width: 650px">
              <Select v-model="formItem.major" multiple>
                <Option
                  :value="s.id"
                  v-for="(s, index) in majorList"
                  :key="index"
                  >{{ s.name }}</Option
                >
              </Select>
            </FormItem>
            </Form>
          </div>
        </div>
        <div slot="footer">
          <Button
            @click="handleSubmit('formClause')"
            :loading="loadingStatus"
            size="large"
            >{{ loadingStatus ? "Uploading" : "确认提交" }}</Button
          >
          <Button @click="handleReset" size="large" style="margin-left: 8px"
            >重置</Button
          >
          <Button
            size="large"
            @click="closeModal('addClause')"
            class="cancel-button"
            >取消</Button
          >
        </div>
      </Modal>
      <Modal
        v-model="modalSwicth.alterClause"
        :width="800"
        :mask-closable="false"
        @on-cancel="closeModal('alterClause')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>标准修改</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('alterClause')"
        ></i>
        <div class="sc-content-wrapper">
          <Form
            ref="formClauseAlter"
            :model="formItemAlter"
            :rules="ruleValidate"
            :label-width="250"
          >
            <FormItem label="条目号：" prop="clauseNo" style="width: 438px">
              <Input v-model="formItemAlter.clauseNo" />
            </FormItem>
            <FormItem label="条目内容：" prop="content" style="width: 650px">
              <Input v-model="formItemAlter.content" placeholder />
            </FormItem>
            <FormItem
              label="要求文件名称："
              prop="fileName"
              style="width: 650px"
            >
              <Input v-model="formItemAlter.fileName" placeholder />
            </FormItem>
            <FormItem label="专业类别：" prop="major" style="width: 650px">
              <Select v-model="formItemAlter.major" multiple>
                <Option
                  :value="s.id"
                  v-for="(s, index) in majorList"
                  :key="index"
                  >{{ s.name }}</Option
                >
              </Select>
            </FormItem>
          </Form>
        </div>
        <div slot="footer">
          <Button size="large" @click="modal_save" class="confirm-button"
            >确定</Button
          >
          <Button
            size="large"
            @click="closeModal('alterClause')"
            class="cancel-button"
            >取消</Button
          >
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import clauseList from "@/components/Common/ClauseList.vue";

let userToken = localStorage.getItem("current_user_token");
// let authData = JSON.parse(localStorage.getItem("authData"));

function formItemFac() {
  return {
    id: 0,
    clauseNo: "",
    content: "",
    fileName: "",
    major: [],
  };
}

export default {
  name: "clauseManage",
  components: {
    clauseList,
  },
  props: {
    standardId: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      update_count: 1,
      currentStandard: {
        standard_no: "",
        name: "",
      },
      majorList:[],
      columnsFormatData: [
        {
          title: "条目号",
          width: 200,
          align: "center",
          key: "clause_no",
        },
        {
          title: "条目内容",
          key: "content",
          align: "center",
          tooltip: "true",
        },
        {
          title: "要求文件名称",
          key: "file_name",
          align: "center",
          width: 300,
        },
        {
          title: "涉及专业",
          key: "major",
          align: "center",
          width: 150,
          render: (h, p) => {
            let majorList = [];
            for(let i of p.row.major.split(';')){
              for(let j of JSON.parse(i)){
                majorList.push(j.name);
              }
            }
            return h(
              "p",
              {},
              majorList.join(',')
            );
          }
        },
        {
          title: "操作",
          align: "center",
          width: 150,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { marginRight: "10px", color: "green" },
                  on: {
                    click: () => {
                      this.modalSwicth.alterClause = true;
                      this.formItemAlter.id = params.row.id;
                      this.formItemAlter.clauseNo = params.row.clause_no;
                      this.formItemAlter.content = params.row.content;
                      this.formItemAlter.fileName = params.row.file_name;
                      this.formItemAlter.category = params.row.category;
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { marginRight: "10px", color: "red" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      modalSwicth: {
        addClause: true,
        alterClause: false,
      },
      formItem: formItemFac(),
      formItemAlter: formItemFac(),
      ruleValidate: {
        clauseNo: [
          {
            required: true,
            type: "string",
            message: "请输入条目号！",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            type: "string",
            message: "请输入条目内容！",
            trigger: "blur",
          },
        ],
        fileName: [
          {
            required: true,
            type: "string",
            message: "请输入要求文件名称！",
            trigger: "blur",
          },
        ],
        major: [
          {
            required: true,
            type: "array",
            message: "请输入专业类别！",
            trigger: "blur",
          },
        ],
      },
      loadingStatus: false,
      atimated: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getData();
    this.getStaticOption();
  },
  methods: {
    openModal(value) {
      this.modalSwicth[value] = true;
    },
    closeModal(value) {
      this.modalSwicth[value] = false;
    },
    getData() {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "standardInfo");
      form.append("id", this.standardId);
      this.loadingStatus = true;
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/archive_manage", form, config)
        .then((response) => {
          this.loadingStatus = false;
          if (response.data.stat === "ok") {
            this.currentStandard = response.data.res_record;
          } else {
            this.$Message.error(response.data.errorInfo);
          }
        })
        .catch((error) => {
          this.$Message.error(error + "未知错误！");
        });
    },
    getStaticOption() {
      this.$axios
        .post(this.$url + "/active_option", {
          userToken: userToken,
          askData: "majorList",
        })
        .then((response) => {
          if (response.data.stat == "ok") {
            this.majorList = response.data.res_record;
          } else {
            this.$Massage.error("获取选项数据失败，网络存在问题！");
          }
        });
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除：",
        content:
          "<p>" +
          "条目：" +
          value.clause_no +
          " " +
          "（" +
          value.content +
          "）" +
          "</p>",
        onOk: () => {
          this.recordFormatDel(value.id);
        },
        onCancel: () => {},
      });
    },
    recordFormatDel(id) {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "delClause");
      form.append("id", id);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/clause_manage", form, config)
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    handleReset() {
      for (let item in this.formItem) {
        this.formItem[item] = "";
      }
      this.file = null;
    },
    handleRemove() {
      this.file = null;
    },
    handleSuccess() {},
    handleUpload(file) {
      if (file.name.split(".").pop() !== "pdf") {
        this.$Notice.warning({
          title: "文件格式不符",
          desc:
            "文件： " + file.name + " 的格式不正确，请选择后缀为“.pdf”的文件!",
        });
      } else if (file.size > 10240000) {
        this.$Notice.warning({
          title: "文件太大",
          desc: "文件：  " + file.name + " 的大小超出限制, 不能大于10MB!",
        });
      } else {
        this.file = file;
        this.formItem.pdfFile = file.name;
      }
      return false;
    },
    handleUploadAlter(file) {
      if (file.name.split(".").pop() !== "pdf") {
        this.$Notice.warning({
          title: "文件格式不符",
          desc:
            "文件： " + file.name + " 的格式不正确，请选择后缀为“.pdf”的文件!",
        });
      } else if (file.size > 10240000) {
        this.$Notice.warning({
          title: "文件太大",
          desc: "文件：  " + file.name + " 的大小超出限制, 不能大于10MB!",
        });
      } else {
        this.fileAlter = file;
        this.formItemAlter.pdfFile = file.name;
      }
      return false;
    },
    modal_save() {
      this.$refs["formClauseAlter"].validate((valid) => {
        if (valid) {
          let fileObj = this.fileAlter;
          let form = new FormData();
          form.append("file", fileObj);
          form.append("userToken", userToken);
          form.append("askData", "alterClause");
          form.append("info", JSON.stringify(this.formItemAlter));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/clause_manage", form, config)
            .then((response) => {
              this.handleReset();
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("信息修改成功！");
                this.modalSwicth.alterClause = false;
                this.formItemAlter = formItemFac();
                this.update_count++;
              } else {
                this.$Message.error(response.data);
              }
            })
            .catch((error) => {
              this.fileAlter = null;
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let fileObj = this.file;
          let form = new FormData();
          form.append("file", fileObj);
          form.append("userToken", userToken);
          form.append("askData", "addClause");
          form.append("standard_id", this.standardId);
          form.append("info", JSON.stringify(this.formItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/clause_manage", form, config)
            .then((response) => {
              this.handleReset();
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("标准添加成功！");
                this.update_count++;
                this.modalSwicth.addClause = false;
              } else {
                this.$Message.error(response.data);
              }
            })
            .catch((error) => {
              this.file = null;
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 40px;
  width: 1280px;
}

.editor-title,
.preview-title {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.re-l {
  min-height: 750px;
  color: #000;
  border: #aaa solid 1px;
  box-shadow: 1px 2px 1px #aaa;
  text-align: center;
  position: relative;
  margin: 5px;
  padding: 0 10px;
}

.form-wrapper {
  text-align: left;
  padding: 80px 120px 80px 50px;
}
</style>